:root {
  /* Only use CSS vars if not using IE */
  --flash-anim-duration: 10s;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121212;
  background-color: #f6f6f6;
  font-size: 1.05em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
select {
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  border: 1px solid #cecece;
  color: #121212;
  font-size: 1em;
  border-radius: 6px;
  padding: 5px 15px !important;
}

input {
  box-sizing: border-box;
}

input[type='checkbox'] {
  width: 18px;
  height: 18px;
}

select {
  border-radius: 6px 6px 6px 6px;
}

button {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2em;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  border: 1px solid #cecece;
  color: #121212;
  font-size: 1em;
  border-radius: 6px 6px 4px 6px;
  padding: 10px 15px !important;
  min-height: 70px;
  max-height: 300px;
}

input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  border: 1px solid #1591bf;
  border-radius: 6px;
  box-shadow: 0 0 2px 2px rgb(93, 196, 255);
}

textarea:focus {
  border-radius: 6px 6px 4px 6px;
}

select:focus {
  border-radius: 6px 6px 6px 6px;
}

p {
  margin-top: 20px;
  margin-bottom: 10px;
}

p.error {
  font-size: 1em;
  margin: 0px;
  margin-top: 10px;
  color: #d9143a;
}

li {
  margin: 8px 0px;
  margin-left: 10px;
}

.Toastify__toast {
  padding: 10px 10px 10px 15px !important;
  border-radius: 4px !important;
}

.regular-checkbox {
  margin-right: 10px;
}

.delete-color {
  background-color: #d9143a !important;
}
